<template>
  <div class="index">
    <div class="banner"></div>
    <div class="content">
      <div class="box_linkUs">
        <ul>
          <li :class="active == 1 ? 'active' : ''" @click="onClick(1)">
            <div class="title">项目合作</div>
            <div class="box_menu">
              <div class="box">
                <div>
                  <img class="img" src="../../../assets/newIndex/contact_icon4.png" alt="logo" />
                </div>
                <div class="name">陆经理</div>
                <div class="desc">销售经理</div>
              </div>
              <div class="box" style="margin-left: 70px">
                <div>
                  <img class="img" src="../../../assets/newIndex/contact_icon6.png" alt="logo" />
                </div>
                <div>13426463545</div>
              </div>
            </div>
          </li>
          <li :class="active == 2 ? 'active' : ''" @click="onClick(2)">
            <div class="title">广告合作</div>
            <div class="box_menu">
              <div class="box">
                <div>
                  <img class="img" src="../../../assets/newIndex/contact_icon4.png" alt="logo" />
                </div>
                <div class="name">刘女士</div>
                <div class="desc">运营总监</div>
              </div>
              <div class="box" style="margin-left: 70px">
                <div>
                  <img class="img" src="../../../assets/newIndex/contact_icon3.png" alt="logo" />
                </div>
                <div>18500682896@163.com</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="map">
        <div class="box">
          <div class="box_link">
            <img class="img" src="../../../assets/newIndex/contact_icon2.png" alt="logo" />
            <div>
              <div class="title">北京市 · 海淀区</div>
              <div class="txt">知春路北航致真大厦A座四层</div>
            </div>
          </div>
          <div class="box_link" style="padding-top: 20px">
            <img class="img" src="../../../assets/newIndex/contact_icon1.png" alt="logo" />
            <div>
              <div class="title">工作时间</div>
              <div class="txt">周一至周六 09:00 - 18:30</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  components: {},
  data() {
    return {
      active: '2',
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    onClick(str) {
      this.active = str
    },
  },
}
</script>
<style lang="less" scoped>
.index {
  display: contents;
  .banner {
    background-image: url('../../../assets/newIndex/contact_logo.png');
    height: 376px;
    max-width: 1920px;
    margin: 0 auto;
    background-size: cover;
    .txt {
      width: 653px;
      height: 210px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi -52 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      margin: 0 auto;
      padding-top: 132px;
    }
  }
  .content {
    margin: 84px auto 100px auto;
    justify-content: space-between;
    width: 1200px;
    .box_linkUs {
      margin-bottom: 60px;
      ul {
        display: flex;
        justify-content: space-between;
        /* width: 1200px; */
        padding: 0;
        .active {
          background: #ffffff;
          box-shadow: 0px 19px 24px 1px rgba(20, 208, 180, 0.19);
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 2px solid #14d0b4;
        }
        li {
          width: 589px;
          height: 154px;
          background: #f5f5f9;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            padding-top: 15px;
            padding-left: 29px;
            min-width: 87px;
            height: 30px;
            font-size: 22px;
            font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #000000;
          }
          .box_menu {
            padding-bottom: 30px;
            display: flex;
            align-items: end;
            .box {
              display: flex;
              padding-top: 15px;
              padding-left: 29px;
              align-items: center;
              .name {
                margin-left: 15px;
                min-width: 59px;
                height: 28px;
                font-size: 20px;
                font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
                font-weight: normal;
                color: #000000;
              }
              .desc {
                margin-left: 15px;
                min-width: 59px;
                height: 21px;
                font-size: 15px;
                font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
                font-weight: normal;
                color: #999999;
              }
            }

            .img {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
    .map {
      position: relative;
      width: 1200px;
      height: 366px;
      background: url('../../../assets/newIndex/contact_map.png');
      .box {
        position: absolute;
        width: 447px;
        height: 219px;
        background: #eb4d27;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        top: 69px;
        right: 95px;
        .img {
          width: 25px;
          height: 25px;
          margin-top: 10px;
          margin-right: 10px;
        }
        .box_link {
          padding-top: 40px;
          padding-left: 50px;
          display: flex;
          .title {
            min-width: 108px;
            height: 22px;
            font-size: 16px;
            font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #ffffff;
            //line-height: 10px;
          }
          .txt {
            min-width: 265px;
            min-height: 30px;
            font-size: 22px;
            font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #ffffff;
            //line-height: 10px;
          }
        }
      }
    }
  }
}
ul li {
  list-style: none;
}
</style>