var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 1200,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
                model: _vm.orderMainModel,
                rules: _vm.validatorRules
              }
            },
            [
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "订单号",
                            required: "",
                            prop: "orderCode"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入订单号" },
                            model: {
                              value: _vm.orderMainModel.orderCode,
                              callback: function($$v) {
                                _vm.$set(_vm.orderMainModel, "orderCode", $$v)
                              },
                              expression: "orderMainModel.orderCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "订单类型", prop: "ctype" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择订单类型" },
                              model: {
                                value: _vm.orderMainModel.ctype,
                                callback: function($$v) {
                                  _vm.$set(_vm.orderMainModel, "ctype", $$v)
                                },
                                expression: "orderMainModel.ctype"
                              }
                            },
                            [
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v("国内订单")
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v("国际订单")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "订单日期", prop: "orderDate" } },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              showTime: "",
                              valueFormat: "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.orderMainModel.orderDate,
                              callback: function($$v) {
                                _vm.$set(_vm.orderMainModel, "orderDate", $$v)
                              },
                              expression: "orderMainModel.orderDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "订单金额", prop: "orderMoney" } },
                        [
                          _c("a-input-number", {
                            staticStyle: { width: "200px" },
                            model: {
                              value: _vm.orderMainModel.orderMoney,
                              callback: function($$v) {
                                _vm.$set(_vm.orderMainModel, "orderMoney", $$v)
                              },
                              expression: "orderMainModel.orderMoney"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "订单备注", prop: "content" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入订单备注" },
                            model: {
                              value: _vm.orderMainModel.content,
                              callback: function($$v) {
                                _vm.$set(_vm.orderMainModel, "content", $$v)
                              },
                              expression: "orderMainModel.content"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-tabs",
                { attrs: { defaultActiveKey: "1" } },
                [
                  _c("a-tab-pane", { key: "1", attrs: { tab: "客户信息" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "a-row",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { type: "flex", gutter: 16 }
                          },
                          [
                            _c("a-col", { attrs: { span: 5 } }, [
                              _vm._v("客户名")
                            ]),
                            _c("a-col", { attrs: { span: 5 } }, [
                              _vm._v("性别")
                            ]),
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v("身份证号码")
                            ]),
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v("手机号")
                            ]),
                            _c("a-col", { attrs: { span: 2 } }, [
                              _vm._v("操作")
                            ])
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.orderMainModel.jeecgOrderCustomerList,
                          function(item, index) {
                            return _c(
                              "a-row",
                              {
                                key: index,
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { type: "flex", gutter: 16 }
                              },
                              [
                                _c(
                                  "a-col",
                                  {
                                    staticStyle: { display: "none" },
                                    attrs: { span: 6 }
                                  },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      [
                                        _c("a-input", {
                                          attrs: { placeholder: "id" },
                                          model: {
                                            value: item.id,
                                            callback: function($$v) {
                                              _vm.$set(item, "id", $$v)
                                            },
                                            expression: "item.id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      [
                                        _c("a-input", {
                                          attrs: { placeholder: "客户名" },
                                          model: {
                                            value: item.name,
                                            callback: function($$v) {
                                              _vm.$set(item, "name", $$v)
                                            },
                                            expression: "item.name"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      [
                                        _c(
                                          "a-select",
                                          {
                                            attrs: { placeholder: "性别" },
                                            model: {
                                              value: item.sex,
                                              callback: function($$v) {
                                                _vm.$set(item, "sex", $$v)
                                              },
                                              expression: "item.sex"
                                            }
                                          },
                                          [
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "1" } },
                                              [_vm._v("男")]
                                            ),
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "2" } },
                                              [_vm._v("女")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          prop:
                                            "jeecgOrderCustomerList." +
                                            index +
                                            ".idcard",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请输入身份证号",
                                              trigger: "blur"
                                            },
                                            {
                                              pattern: _vm.rules.IDCard,
                                              message: "身份证号格式不对!"
                                            }
                                          ]
                                        }
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: { placeholder: "身份证号" },
                                          model: {
                                            value: item.idcard,
                                            callback: function($$v) {
                                              _vm.$set(item, "idcard", $$v)
                                            },
                                            expression: "item.idcard"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          prop:
                                            "jeecgOrderCustomerList." +
                                            index +
                                            ".telphone",
                                          rules: _vm.rules.mobile
                                        }
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: { placeholder: "手机号" },
                                          model: {
                                            value: item.telphone,
                                            callback: function($$v) {
                                              _vm.$set(item, "telphone", $$v)
                                            },
                                            expression: "item.telphone"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 2 } },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      [
                                        _c("a-icon", {
                                          staticStyle: { fontSize: "20px" },
                                          attrs: { type: "minus-circle" },
                                          on: {
                                            click: function($event) {
                                              return _vm.delRowCustom(index)
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }
                        ),
                        _c(
                          "a-button",
                          {
                            staticStyle: { width: "98%", "margin-top": "10px" },
                            attrs: { type: "dashed" },
                            on: { click: _vm.addRowCustom }
                          },
                          [
                            _c("a-icon", { attrs: { type: "plus" } }),
                            _vm._v(" 添加客户信息\n            ")
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ]),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: "机票信息", forceRender: "" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "a-row",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { type: "flex", gutter: 16 }
                            },
                            [
                              _c("a-col", { attrs: { span: 6 } }, [
                                _vm._v("航班号")
                              ]),
                              _c("a-col", { attrs: { span: 6 } }, [
                                _vm._v("航班时间")
                              ]),
                              _c("a-col", { attrs: { span: 6 } }, [
                                _vm._v("操作")
                              ])
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.orderMainModel.jeecgOrderTicketList,
                            function(item, index) {
                              return _c(
                                "a-row",
                                {
                                  key: index,
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { type: "flex", gutter: 16 }
                                },
                                [
                                  _c(
                                    "a-col",
                                    {
                                      staticStyle: { display: "none" },
                                      attrs: { span: 6 }
                                    },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        [
                                          _c("a-input", {
                                            attrs: { placeholder: "id" },
                                            model: {
                                              value: item.id,
                                              callback: function($$v) {
                                                _vm.$set(item, "id", $$v)
                                              },
                                              expression: "item.id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            prop:
                                              "jeecgOrderTicketList." +
                                              index +
                                              ".ticketCode",
                                            rules: {
                                              required: true,
                                              message: "请输入航班号",
                                              trigger: "blur"
                                            }
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: { placeholder: "航班号" },
                                            model: {
                                              value: item.ticketCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "ticketCode",
                                                  $$v
                                                )
                                              },
                                              expression: "item.ticketCode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        [
                                          _c("a-date-picker", {
                                            attrs: {
                                              placeholder: "航班时间",
                                              valueFormat: "YYYY-MM-DD"
                                            },
                                            model: {
                                              value: item.tickectDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "tickectDate",
                                                  $$v
                                                )
                                              },
                                              expression: "item.tickectDate"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        [
                                          _c("a-icon", {
                                            staticStyle: { fontSize: "20px" },
                                            attrs: { type: "minus-circle" },
                                            on: {
                                              click: function($event) {
                                                return _vm.delRowTicket(index)
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "a-button",
                            {
                              staticStyle: {
                                width: "98%",
                                "margin-top": "10px"
                              },
                              attrs: { type: "dashed" },
                              on: { click: _vm.addRowTicket }
                            },
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _vm._v(" 添加机票信息\n            ")
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }