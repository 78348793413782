var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 1200,
        visible: _vm.visible,
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: _vm.confirmLoading
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
                model: _vm.model
              }
            },
            [
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 0 } },
                [
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "订单号",
                            prop: "orderCode",
                            rules: [
                              { required: true, message: "请输入订单号!" }
                            ]
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入订单号" },
                            model: {
                              value: _vm.model.orderCode,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "orderCode", $$v)
                              },
                              expression: "model.orderCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "订单类型" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择订单类型" },
                              model: {
                                value: _vm.model.ctype,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "ctype", $$v)
                                },
                                expression: "model.ctype"
                              }
                            },
                            [
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v("国内订单")
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v("国际订单")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "订单日期" } },
                        [
                          _c("a-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              showTime: "",
                              valueFormat: "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.model.orderDate,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "orderDate", $$v)
                              },
                              expression: "model.orderDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 0 } },
                [
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "订单金额" } },
                        [
                          _c("a-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入订单金额" },
                            model: {
                              value: _vm.model.orderMoney,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "orderMoney", $$v)
                              },
                              expression: "model.orderMoney"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "订单备注" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入订单备注" },
                            model: {
                              value: _vm.model.content,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "content", $$v)
                              },
                              expression: "model.content"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tabs",
            {
              on: { change: _vm.handleChangeTabs },
              model: {
                value: _vm.activeKey,
                callback: function($$v) {
                  _vm.activeKey = $$v
                },
                expression: "activeKey"
              }
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "客户信息", forceRender: true } },
                [
                  _c("j-vxe-table", {
                    ref: "editableTable1",
                    staticStyle: { "margin-top": "8px" },
                    attrs: {
                      toolbar: "",
                      "row-number": "",
                      "row-selection": "",
                      "keep-source": "",
                      height: 300,
                      loading: _vm.table1.loading,
                      dataSource: _vm.table1.dataSource,
                      columns: _vm.table1.columns
                    }
                  })
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "机票信息", forceRender: true } },
                [
                  _c("j-vxe-table", {
                    ref: "editableTable2",
                    staticStyle: { "margin-top": "8px" },
                    attrs: {
                      toolbar: "",
                      "row-number": "",
                      "row-selection": "",
                      "keep-source": "",
                      height: 300,
                      loading: _vm.table2.loading,
                      dataSource: _vm.table2.dataSource,
                      columns: _vm.table2.columns
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }