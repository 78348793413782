<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="柜子编号" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="code">
              <a-input v-model="model.code" placeholder="请输入柜子编号"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="所在位置" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="position">
              <a-input v-model="model.position" placeholder="请输入所在位置"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="name">
              <a-input v-model="model.name" placeholder="请输入名称"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="mac地址1" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="macAddrOne">
              <a-input v-model="model.macAddrOne" placeholder="请输入蓝牙板mac地址1"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="mac1密码" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="passwordOne">
              <a-input v-model="model.passwordOne" placeholder="请输入蓝牙板mac地址1的密码"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" v-if="!model.id">
            <a-form-model-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="type">
              <j-dict-select-tag type="list" v-model="model.type" dictCode="cabinet_type" placeholder="请选择类型" :disabled="disableType"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12"  v-if="model.type==20||model.type==24">
            <a-form-model-item label="mac地址2" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="macAddrTwo">
              <a-input v-model="model.macAddrTwo" placeholder="请输入蓝牙板mac地址2"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12"  v-if="model.type==20||model.type==24">
            <a-form-model-item label="mac2密码" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="passwordTwo">
              <a-input v-model="model.passwordTwo" placeholder="请输入蓝牙板mac地址2的密码"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="status">
              <j-dict-select-tag type="radio" v-model="model.status" dictCode="status" placeholder="请选择状态" />
            </a-form-model-item>
          </a-col>

          <a-col :span="12">
            <a-form-model-item label="关门状态值" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="openType">
              <a-input v-model="model.openType" placeholder=""></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
  </a-spin>
</template>

<script>

  import { httpAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/util'

  export default {
    name: 'CabinetForm',
    components: {
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        model:{
          id:''
         },
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
           code: [
              { required: true, message: '请输入柜子编号!'},
           ],
           position: [
              { required: true, message: '请输入所在位置!'},
           ],
           macAddrOne: [
              { required: true, message: '请输入mac地址1!'},
           ],
           passwordOne: [
              { required: true, message: '请输入mac地址1的密码!'},
           ],
           macAddrTwo: [
              { required: true, message: '请输入mac地址2!'},
           ],
           passwordTwo: [
              { required: true, message: '请输入mac地址2的密码!'},
           ],
           name: [
              { required: true, message: '请输入名称!'},
           ],
           type: [
              { required: true, message: '请输入类型!'},
           ],
           status: [
              { required: true, message: '请输入状态!'},
           ],
          openType: [
            { required: false, message: '请输入状态!'},
          ],
        },
        url: {
          add: "/ylpp/cabinet/add",
          edit: "/ylpp/cabinet/edit",
          queryById: "/ylpp/cabinet/queryById"
        },
        disableType: false
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        this.model = Object.assign({}, record);
        this.visible = true;
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            httpAction(httpurl,this.model,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }

        })
      },
    }
  }
</script>