var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index" }, [
    _c("div", { staticClass: "banner" }),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "box_linkUs" }, [
        _c("ul", [
          _c(
            "li",
            {
              class: _vm.active == 1 ? "active" : "",
              on: {
                click: function($event) {
                  return _vm.onClick(1)
                }
              }
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("项目合作")]),
              _vm._m(0)
            ]
          ),
          _c(
            "li",
            {
              class: _vm.active == 2 ? "active" : "",
              on: {
                click: function($event) {
                  return _vm.onClick(2)
                }
              }
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("广告合作")]),
              _vm._m(1)
            ]
          )
        ])
      ]),
      _vm._m(2)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box_menu" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", [
          _c("img", {
            staticClass: "img",
            attrs: {
              src: require("../../../assets/newIndex/contact_icon4.png"),
              alt: "logo"
            }
          })
        ]),
        _c("div", { staticClass: "name" }, [_vm._v("陆经理")]),
        _c("div", { staticClass: "desc" }, [_vm._v("销售经理")])
      ]),
      _c(
        "div",
        { staticClass: "box", staticStyle: { "margin-left": "70px" } },
        [
          _c("div", [
            _c("img", {
              staticClass: "img",
              attrs: {
                src: require("../../../assets/newIndex/contact_icon6.png"),
                alt: "logo"
              }
            })
          ]),
          _c("div", [_vm._v("13426463545")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box_menu" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", [
          _c("img", {
            staticClass: "img",
            attrs: {
              src: require("../../../assets/newIndex/contact_icon4.png"),
              alt: "logo"
            }
          })
        ]),
        _c("div", { staticClass: "name" }, [_vm._v("刘女士")]),
        _c("div", { staticClass: "desc" }, [_vm._v("运营总监")])
      ]),
      _c(
        "div",
        { staticClass: "box", staticStyle: { "margin-left": "70px" } },
        [
          _c("div", [
            _c("img", {
              staticClass: "img",
              attrs: {
                src: require("../../../assets/newIndex/contact_icon3.png"),
                alt: "logo"
              }
            })
          ]),
          _c("div", [_vm._v("18500682896@163.com")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "map" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box_link" }, [
          _c("img", {
            staticClass: "img",
            attrs: {
              src: require("../../../assets/newIndex/contact_icon2.png"),
              alt: "logo"
            }
          }),
          _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v("北京市 · 海淀区")]),
            _c("div", { staticClass: "txt" }, [
              _vm._v("知春路北航致真大厦A座四层")
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "box_link", staticStyle: { "padding-top": "20px" } },
          [
            _c("img", {
              staticClass: "img",
              attrs: {
                src: require("../../../assets/newIndex/contact_icon1.png"),
                alt: "logo"
              }
            }),
            _c("div", [
              _c("div", { staticClass: "title" }, [_vm._v("工作时间")]),
              _c("div", { staticClass: "txt" }, [
                _vm._v("周一至周六 09:00 - 18:30")
              ])
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }