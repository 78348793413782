var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index" }, [
    _vm._m(0),
    _c("div", { staticClass: "product" }, [
      _c("ul", [
        _c("li", { staticClass: "pc", on: { click: _vm.login } }, [
          _c("img", {
            staticClass: "img",
            attrs: { src: require("../../../assets/newIndex/pc_icon.png") }
          }),
          _c("div", { staticClass: "txt" }, [_vm._v("后台管理")]),
          _c("div", { staticClass: "txt1" }, [_vm._v("点击登录")])
        ]),
        _vm._m(1),
        _vm._m(2)
      ])
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "slogan" }, [
        _vm._v("毅力实验通，你的实验设备管理专家")
      ]),
      _vm._m(3),
      _c("div", { staticClass: "button_box" }, [
        _c("div", { staticClass: "button", on: { click: _vm.yuyue } }, [
          _c("img", {
            staticClass: "img",
            attrs: {
              src: require("../../../assets/newIndex/action.png"),
              alt: "logo"
            }
          }),
          _vm._v("预约演示\n      ")
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner" }, [
      _c("div", { staticClass: "slogan" }, [_vm._v("全新出发 · 极致体验")]),
      _c("div", { staticClass: "describe" }, [
        _vm._v("全新视觉改版，清爽界面更有温度，极致体验更加智能")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "gld" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("../../../assets/newIndex/xsd_icon.png") }
      }),
      _c("div", { staticClass: "txt" }, [_vm._v("学生端小程序")]),
      _c("div", { staticClass: "txt1" }, [_vm._v("版本: V2.1.1")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "xcx" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("../../../assets/newIndex/gld.jpg") }
      }),
      _c("div", { staticClass: "txt" }, [_vm._v("管理端小程序")]),
      _c("div", { staticClass: "txt1" }, [_vm._v("版本: V2.1.1")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "data" }, [
      _c("ul", [
        _c("li", [
          _c("div", { staticClass: "number_box" }, [
            _c("span", { staticClass: "number" }, [_vm._v("8")]),
            _c("span", { staticClass: "year" }, [_vm._v("年+")])
          ]),
          _c("div", { staticClass: "describe1" }, [_vm._v("专业数据")])
        ]),
        _c("li", [
          _c("div", { staticClass: "number_box" }, [
            _c("span", { staticClass: "number" }, [_vm._v("6235")]),
            _c("span", { staticClass: "year" }, [_vm._v("+")])
          ]),
          _c("div", { staticClass: "describe1" }, [_vm._v("服务学生")])
        ]),
        _c("li", [
          _c("div", { staticClass: "number_box" }, [
            _c("span", { staticClass: "number" }, [_vm._v("23")]),
            _c("span", { staticClass: "year" }, [_vm._v("+")])
          ]),
          _c("div", { staticClass: "describe1" }, [_vm._v("接入院校")])
        ]),
        _c("li", [
          _c("div", { staticClass: "number_box" }, [
            _c("span", { staticClass: "number" }, [_vm._v("3835")]),
            _c("span", { staticClass: "year" }, [_vm._v("+")])
          ]),
          _c("div", { staticClass: "describe1" }, [_vm._v("管理设备")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }