<template>
  <div class="index">
    <div class="banner">
      <div class="slogan">全新出发 · 极致体验</div>
      <div class="describe">全新视觉改版，清爽界面更有温度，极致体验更加智能</div>
    </div>
    <div class="product">
      <ul>
        <li class="pc"  @click="login">
          <img class="img" src="../../../assets/newIndex/pc_icon.png" />
          <div class="txt">后台管理</div>
          <div class="txt1">点击登录</div>
        </li>
        <li class="gld">
          <img class="img" src="../../../assets/newIndex/xsd_icon.png" />
          <div class="txt">学生端小程序</div>
          <div class="txt1">版本: V2.1.1</div>
        </li>
        <li class="xcx">
          <img class="img" src="../../../assets/newIndex/gld.jpg" />
          <div class="txt">管理端小程序</div>
          <div class="txt1">版本: V2.1.1</div>
        </li>
      </ul>
    </div>
    <div class="content">
      <div class="slogan">毅力实验通，你的实验设备管理专家</div>
      <div class="data">
        <ul>
          <li>
            <div class="number_box"><span class="number">8</span><span class="year">年+</span></div>
            <div class="describe1">专业数据</div>
          </li>
          <li>
            <div class="number_box"><span class="number">6235</span><span class="year">+</span></div>
            <div class="describe1">服务学生</div>
          </li>
          <li>
            <div class="number_box"><span class="number">23</span><span class="year">+</span></div>
            <div class="describe1">接入院校</div>
          </li>
          <li>
            <div class="number_box"><span class="number">3835</span><span class="year">+</span></div>
            <div class="describe1">管理设备</div>
          </li>
        </ul>
      </div>
      <div class="button_box">
        <div class="button"  @click="yuyue">
          <img class="img" src="../../../assets/newIndex/action.png" alt="logo" />预约演示
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  components: {
  },
  data() {
    return {
      active1: '1',
      active2: '1',
      active3: '1',
      active3Child: '1',
      active: '',
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    yuyue() {
      this.$parent.handleTabClick('yy')
    },
    content2Click(flag) {
      this.active1 = flag
    },
    content3Click(flag) {
      this.active2 = flag
    },
    content4Click(flag) {
      this.active3 = flag
    },
    content4ChildClick(flag) {
      this.active3Child = flag
    },
    login() {
      this.$router.push({ path: '/user/login' });
    },
  },
}
</script>
<style lang="less" scoped>
.index {
  display: contents;
  height: 100%;
  width: 100%;
  background: #fff !important;
  .banner {
    background: url('../../../assets/newIndex/download_banner.png');
    height: 583px;
    max-width: 1920px;
    width: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .slogan {
      min-width: 434px;
      height: 78px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
      line-height: 71px;
    }
    .describe {
      min-width: 383px;
      height: 31px;
      font-size: 16px;
      font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
    }
  }
  .product {
    display: flex;
    justify-content: center;
    margin-top: -90px;
    ul {
      display: flex;

      .pc,
      .gld,
      .xcx {
        width: 280px;
        height: 260px;
        .img {
          width: 120px;
          height: 120px;
          margin-top: 30px;
          margin-left: 80px;
        }

      }
      .pc {
        .txt {
          text-align: center;
          margin-top: 20px;
          min-width: 79px;
          height: 28px;
          font-size: 20px;
          font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
        }
        .txt1 {
          min-width: 80px;
          text-align: center;
          height: 22px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
        }
        margin-right: 24px;
        background: linear-gradient(357deg, #65ddc1 0%, #18d1b5 100%);
        border-radius: 21px 21px 21px 21px;
        opacity: 1;
      }
      .xcx {
        .txt {
          text-align: center;
          margin-top: 20px;
          min-width: 79px;
          height: 28px;
          font-size: 20px;
          font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
        }
        .txt1 {
          min-width: 80px;
          text-align: center;
          height: 22px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
        }
        .img{
          border-radius: 50%;
    padding: 11px;
    background: #fff;
        }
        background: linear-gradient(357deg, #65ddc1 0%, #18d1b5 100%);
        border-radius: 21px 21px 21px 21px;
        opacity: 1;
      }
      .gld {
        .txt {
          text-align: center;
          margin-top: 20px;
          min-width: 79px;
          height: 28px;
          font-size: 20px;
          font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
        }
        .txt1 {
          min-width: 80px;
          text-align: center;
          height: 22px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
        }
        margin-right: 24px;
        background: linear-gradient(357deg, #65ddc1 0%, #18d1b5 100%);
        border-radius: 21px 21px 21px 21px;
        opacity: 1;
      }
      .sm {
        margin-left: 10px;
        .txt {
          width: 106px;
          height: 31px;
          font-size: 12px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #666666;
          line-height: 14px;
          padding-left: 9px;
          text-align: center;
        }
      }
    }
  }
  .content {
    background: url('../../../assets/newIndex/download_content.png');
    max-width: 1920px;
    height: 800px;
    .slogan {
      padding-top: 248px;
      min-width: 690px;
      height: 326px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
      text-align: center;
      line-height: 71px;
    }
    .describe {
      min-width: 716px;
      //height: 47px;
      font-size: 16px;
      font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
      //line-height: 23px;
      text-align: center;
    }
    .data {
      margin-top: 70px;
      ul {
        display: flex;
        justify-content: center;
        li {
          margin-right: 115px;
        }
        li:last-child {
          margin-right: 0 !important;
        }
        .number_box {
          min-width: 85px;
          height: 120px;
          .number {
            min-width: 36px;
            height: 81px;
            font-size: 67px;
            font-family: DIN-Regular, DIN;
            font-weight: 400;
            color: #07d5af;
            //line-height: 23px;
          }
          .year {
            min-width: 40px;
            height: 36px;
            font-size: 25px;
            font-family: Alibaba PuHuiTi 3-105 Heavy, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #07d5af;
            line-height: 23px;
          }
        }
        .describe1 {
          text-align: center;
          min-width: 62px;
          height: 22px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #000000;
        }
      }
    }
    .button_box {
      .button {
        cursor: pointer;
        color:#fff;
        width: 100px;
        height: 50px;
        background: linear-gradient(90deg, #14d0b4 0%, #66ddc1 100%);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        text-align: center;
        line-height: 50px;
        margin: 70px auto 0 auto;
        .img{
          width: 12.5px;
    height: 12.5px;
    margin-right: 5px;
        }
      }
    }
  }
}
ul li {
  list-style: none;
}
</style>